import CryptoJS from "crypto-js";


export const encryptData = (data, salt) => {
  let key = "";
  salt && salt !== undefined && !salt.isEmpty()
    ? (key = salt)
    : (key = process.env.VUE_APP_ENCRYPTION_KEY);

  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export const decryptData = (ciphertext, salt) => {
  let key = "";
  salt && salt !== undefined && !salt.isEmpty()
    ? (key = salt)
    : (key = process.env.VUE_APP_ENCRYPTION_KEY);

  const bytes = CryptoJS.AES.decrypt(ciphertext, key);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export function toLocaleDateString(date, locales) {
  try {
    return new Date(date + ' UTC').toLocaleDateString(locales, {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      // timeZone: "UTC"
      timeZoneName: "short"
    });
  } catch (error) {
    console.warn(error);
    return "Invalid data";
  }
}

export function toLocaleTimeString(date) {
  try {
    return new Date(date + ' UTC').toLocaleTimeString({
      // timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: "short"
    });
  } catch (error) {
    console.warn(error);
    return "Invalid data";
  }
}
